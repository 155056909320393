import React from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Link } from 'gatsby';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    textDecoration: 'none',
  },
  logo: {
    maxHeight: 35,
    marginRight: theme.spacing(1),
  },
  right: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridGap: theme.spacing(3),
  },
  moreButton: {
    color: 'white',
  },
  moreRed: {
    color: theme.palette.primary.main,
  },
  title: {
    textDecoration: 'none',
    flex: 1,
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'none',
    },
    color: 'rgb(0,0,0,0.87)',
    textDecoration: 'none',
  },
  footer: {
    padding: 20,
    marginTop: 'auto',
    backgroundColor: 'white',
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    marginLeft: -12,
  },
  button: {
    textTransform: 'capitalize',
  },
}));

function ElevationScroll(props) {
  const theme = useTheme();
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: trigger
      ? {
          backgroundColor: 'white',
          color: theme.palette.primary.main,
          transition: 'background-color 0.5s ease',
        }
      : {
          transition: 'background-color 0.5s ease',
        },
  });
}

export default function Navbar(props) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const { hasSidebar, drawerToggle, hideTitle } = props;
  const classes = useStyles();
  return (
    <ElevationScroll {...props}>
      <AppBar>
        <Toolbar>
          {hasSidebar && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={drawerToggle}
              className={classes.navIconHide}
            >
              <Icon className={trigger && classes.navIcon}>menu</Icon>
            </IconButton>
          )}
          {hideTitle ? (
            <div className={classes.title} />
          ) : (
            <Typography
              component={Link}
              to="/"
              className={classes.title}
              variant="h6"
              color="inherit"
              noWrap
            >
              Pushback
            </Typography>
          )}
          <Hidden smDown>
            <div className={classes.right}>
              <Button
                className={classes.button}
                component={Link}
                to="/pricing/"
                color="inherit"
              >
                Pricing
              </Button>
              <Button
                className={classes.button}
                component={Link}
                to="/demo/"
                color="inherit"
              >
                Demo
              </Button>
              <Button
                className={classes.button}
                component={Link}
                to="/docs/getting-started"
                color="inherit"
              >
                Docs
              </Button>
              <Button
                className={classes.button}
                component="a"
                color="inherit"
                href="https://app.pushback.io/tryitout"
              >
                Try Now
              </Button>
              <Button
                className={classes.button}
                component="a"
                color="inherit"
                href="https://app.pushback.io/login"
              >
                Login
              </Button>
            </div>
          </Hidden>
          <Hidden mdUp>
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <>
                  <IconButton
                    className={trigger ? classes.moreRed : classes.moreButton}
                    aria-label="More"
                    aria-haspopup="true"
                    {...bindTrigger(popupState)}
                  >
                    <Icon>more_vert_icon</Icon>
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem
                      onClick={popupState.close}
                      component={Link}
                      to="/pricing/"
                    >
                      Pricing
                    </MenuItem>
                    <MenuItem
                      onClick={popupState.close}
                      component={Link}
                      to="/demo/"
                    >
                      Demo
                    </MenuItem>
                    <MenuItem
                      onClick={popupState.close}
                      component={Link}
                      to="/docs/getting-started"
                    >
                      Docs
                    </MenuItem>
                    <MenuItem
                      component="a"
                      onClick={popupState.close}
                      href="https://app.pushback.io/tryitout"
                    >
                      Try Now
                    </MenuItem>
                    <MenuItem
                      component="a"
                      href="https://app.pushback.io/login"
                      onClick={popupState.close}
                    >
                      Login
                    </MenuItem>
                  </Menu>
                </>
              )}
            </PopupState>
          </Hidden>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
}
