import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'gatsby';

import { COLORS } from '../styles/constants';

const useStyles = makeStyles((theme) => ({
  footer: {
    zIndex: 10,
    borderTop: `1px solid ${COLORS.gray}`,
    padding: 10,
    marginTop: 'auto',
    backgroundColor: 'white',
    minHeight: '16vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },

  links: {
    display: 'grid',

    gridTemplateColumns: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    gridGap: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    marginRight: 10,
    color: COLORS.pushback,
    textDecoration: 'none',
  },
}));

const Footer = ({ siteTitle }) => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg" component="div" className={classes.container}>
        <div className={classes.links}>
          <Link className={classes.link} to="/contact">
            {' '}
            Contact Us{' '}
          </Link>
          <Link className={classes.link} to="/privacy.html">
            {' '}
            Privacy Policy{' '}
          </Link>
        </div>
        <div className={classes.grow} />
        <div style={{ color: COLORS.gray }}>
          © {new Date().getFullYear()}
          {` `}
          {siteTitle}
        </div>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
