export const COLORS = {
  lightWhite: '#FBFDFF',
  lightGray: '#F3F5F6',
  mediumGray: '#B0B8BA',
  gray: '#A0A4A6',
  babyBlue: '#50CAF9',
  blue: '#5368f1',
  pushback: 'rgb(244,67,54)',
};

export const BREAKPOINTS = {
  md: '@media(min-width:960px)',
  topbar: 64,
};

export const GRADIENT = `linear-gradient(to top right, ${COLORS.babyBlue}, ${
  COLORS.blue
})`;

export const BORDER_RADIUS = '10px';
